export const API_BASE_URL = process.env.NODE_ENV == "development" ? "http://localhost:8000/api/corrector" : "https://app.redacaonline.com.br/api/corrector";

export const CORRECTOR_PROFILE_INDEX = `${API_BASE_URL}/corrector`;
export const CORRECTOR_UPDATE_DATA = `${CORRECTOR_PROFILE_INDEX}/update`;
export const CORRECTOR_UPDATE_CURRICULUM = `${CORRECTOR_PROFILE_INDEX}/update-curriculum`;
export const CORRECTOR_UPDATE_EDUCATION = `${CORRECTOR_PROFILE_INDEX}/update-education`;
export const CORRECTOR_CHANGE_PASSWORD = `${CORRECTOR_PROFILE_INDEX}/change-password`;
export const CORRECTOR_CHANGE_AVATAR = `${CORRECTOR_PROFILE_INDEX}/change-avatar`;
export const CORRECTOR_GET_INFO_DASHBOARD = `${CORRECTOR_PROFILE_INDEX}/info-dashboard`;
export const ADD_SPECIALIZATION = `${CORRECTOR_PROFILE_INDEX}/add-specialization`;
export const REMOVE_SPECIALIZATION = `${CORRECTOR_PROFILE_INDEX}/remove-specialization`;

export const GRAPH_ESSAYS_CORRECTED = `${API_BASE_URL}/chart/essays-corrected`;

export const ESSAYS_CORRECT_INDEX = `${API_BASE_URL}/essays`;
export const ESSAYS_CORRECTED_INDEX = `${API_BASE_URL}/corrections`;

export const GET_THEME = `${API_BASE_URL}/correction/theme`;
export const CORRECTION_STUDENT = `${API_BASE_URL}/correction/student`;

export const CORRECTION_NOTES = `${API_BASE_URL}/correction/notes`;
export const CORRECTION_TAG = `${API_BASE_URL}/correction/tag`;
export const CORRECTION_COMPETENCE = `${API_BASE_URL}/correction/competence`;

export const TEST_SHOW = `${API_BASE_URL}/edital`;

export const CHAT_INDEX = `${API_BASE_URL}/chat`;

export const SUPPORT_INDEX = `${API_BASE_URL}/support`;

export const ACCOMPANIMENT_INDEX = `${API_BASE_URL}/accompaniments`;

export const TESTS_INDEX = `${API_BASE_URL}/tests`;

export const NOTIFICATIONS_CHAT = `${API_BASE_URL}/notifications/chat`;
export const NOTIFICATIONS_ACCOMPANIMENTS = `${API_BASE_URL}/notifications/accompaniments`;
